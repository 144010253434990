import React, { useState } from "react";
import { Steps, Row, Col, Button, Card, Tag } from "antd";
import {
  CloudDownloadOutlined,
  FireOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
interface FilingProps {
  filingId: string;
}

export const MovementSteps: React.FC<FilingProps> = (props) => {
  const [current, setCurrent] = useState(props.filingId);

  const filingStatus = <Tag color="orange">Pending</Tag>;
  const movementItems = [
    {
      title: "Import",
      description: "Loading movements from netsuite into our database",
      icon: <CloudDownloadOutlined />,
    },
    {
      title: "Process",
      description: "Prepare movements to be send to Taxdoo",
      icon: <FireOutlined />,
    },
    {
      title: "Export",
      description: "Sending movements to Taxdoo",
      icon: <CloudUploadOutlined />,
    },
    {
      title: "Done",
      description: <CheckCircleOutlined />,
    },
  ];

  const handleRun = () => {
    switch (current) {
      case 0:
        console.log("run load transaction function");
        break;
      case 1:
        console.log("run process transaction function");
        break;
      case 2:
        console.log("run send filing to Taxdoo function");
        break;
      default:
        console.log("please choose a step to run");
        break;
    }
  };

  return (
    <Card title={"Movements"} extra={filingStatus}>
      <Steps
        direction="horizontal"
        size="small"
        current={0}
        items={movementItems}
      ></Steps>
      <Row>
        <Col span={24} offset={11}>
          <Button
            onClick={handleRun}
            type="primary"
            style={{ marginTop: 16, right: "0px" }}
          >
            Import NS movements
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
