import React from "react";
import {
  HomeOutlined,
  AreaChartOutlined,
  FileSyncOutlined,
  LogoutOutlined,
  UserOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Menu, Layout, Button, Avatar, Typography, Row, Col } from "antd";
import type { MenuProps } from 'antd';
import { useAuth } from "../hooks/use-auth";
import { useMatches, Link } from "react-router-dom";

const items: MenuProps["items"] = [
  {
    key: "app-brands",
    icon: <HomeOutlined />,
    label: <Link to="/app/brands">Brands</Link>,
  },
  {
    key: "app-reports",
    label: <Link to="/app/reports">Brand Cockpit</Link>,
    icon: <AreaChartOutlined />,
  },
  {
    key: "app-report-data",
    label: <Link to="/app/report-data">Data Sources</Link>,
    icon: <FileSyncOutlined />,
  },
  {
    key: "app-taxdoo",
    label: <Link to="/app/taxdoo/filings">Taxdoo</Link>,
    icon: <FileDoneOutlined />,
  },
];

const SideNav: React.FC = () => {
  const { logout, user } = useAuth();

  const matches = useMatches();
  console.log(matches)
  const match = matches[matches.length - 1];

  return <Layout.Sider width="256" theme="light" style={{ height: "100vh", padding: 8 }} >
    <Typography.Title style={{textAlign: 'center'}} level={3}>FlightDeck</Typography.Title>
    <Menu
      defaultSelectedKeys={[match.id]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
      items={items}
      style={{border: 'none'}}
    />
    <Row gutter={[16, 16]} align={"middle"} justify={"space-between"} >
      <Col >
        <Avatar
          size={32}
          shape="circle"
          icon={<UserOutlined />}
          src={user?.profilePictureUrl}
        />
      </Col>
      <Col >
        <span>
          <Typography> {user?.name}</Typography>
        </span>
      </Col>
      <Col>
        <Button
          onClick={() => logout()}
          icon={<LogoutOutlined />}
        />
      </Col>
    </Row>
  </Layout.Sider>
};

export default SideNav;

