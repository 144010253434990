import React, { useState } from "react";
import { Button, DatePicker, DatePickerProps, Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { PageHeader } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { Link } from "react-router-dom";
interface DataType {
  period: string;
  loaded: string;
  processed: string;
  sent: string;
  created_at: string;
  created_by: string;
  id:number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Period",
    rowScope: "row",
    render: ({ period, id }) => (
      <Link to={`/app/taxdoo/filings/${id}`}>{period}</Link>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  // {
  //   title: "Processed",
  //   dataIndex: "processed",
  // },
  // {
  //   title: "Sent",
  //   dataIndex: "sent",
  // },
  {
    title: "Created At",
    dataIndex: "created_at",
  },
  {
    title: "Created By",
    dataIndex: "created_by",
  },
];

const data: DataType[] = [
  // {
  //   period: "Jan 2023",
  //   loaded: "true",
  //   processed: "true",
  //   sent: "true",
  //   created_at: "6/23/2013",
  //   created_by: "Jan.niklas@mantarobrands.com",
  //   id: 1,
  // },
  // {
  //   period: "Feb 2023",
  //   loaded: "true",
  //   processed: "true",
  //   sent: "false",
  //   created_at: "6/23/2013",
  //   created_by: "nderim.sali@mantarobrands.com",
  //   id: 2,
  // },
  // {
  //   period: "March 2023",
  //   loaded: "true",
  //   processed: "false",
  //   sent: "false",
  //   created_at: "6/23/2013",
  //   created_by: "Tunar.mohamedov@mantarobrands.com",
  //   id: 3,
  // },
  {
    period: "April 2023",
    // loaded: "true",
    // processed: "false",
    status: "In progress",
    created_at: "2023-05-03",
    created_by: "jan-niklas.kohlhaas@mantarobrands.com",
    id: 4,
  },
];


// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = (current:dayjs.Dayjs) => {
  // Can not select days before today and today
  return (
    current.year() + 1 > dayjs().year() &&
    current.month() + 1 > dayjs().month() ||
    current.isAfter(dayjs().endOf("day"))
  );
};

const Taxdoo: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const button = (
    <Button onClick={showModal} type="primary" icon={<PlusOutlined />} />
  );

  // let navigate = useNavigate();
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <>
      <PageHeader title="Taxdoo Filings" extra={button} />

      <Table
        columns={columns}
        pagination={false}
        size="small"
        dataSource={data}
        bordered
      />

      <Modal
        title="Taxdoo Filing Creation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <DatePicker
          onChange={onChange}
          picker="month"
          disabledDate={disabledDate}
        />
      </Modal>
    </>
  );
};

export default Taxdoo;
