import React from "react";
import { Row, Col } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router-dom";
import { TaxdooFilingSummary } from "../../components/filing-summary";
import { TransactionSteps } from "./components/transaction-steps";
import { MovementSteps } from "./components/movement-steps";

export const TaxdooFilingDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <PageHeader title={"Taxdoo Filing Details"} onBack={onBack} />
      <Row gutter={[0, 20]}>
        <Col span={22} offset={1}>
          <TaxdooFilingSummary />
        </Col>
        <Col span={22} offset={1}>
          <TransactionSteps filingId={id} />
        </Col>
        <Col span={22} offset={1}>
          <MovementSteps filingId={id} />
        </Col>
      </Row>
    </>
  );
};
