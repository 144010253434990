import LoginPage from "../pages/login";
import { GoogleAuthCallbackPage } from "../pages/google-auth-callback";
import { Navigate, createBrowserRouter } from "react-router-dom";
import AppSkeleton from "../layouts/app-skeleton";
import Brands from "../pages/brands";
import Reports from "../pages/reports";
import ReportSource from "../pages/report-source";
import Brand from "../pages/brand";
import Taxdoo from "../pages/taxdoo-filings/list";
import {TaxdooFilingDetails} from "../pages/taxdoo-filings/details";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <Navigate to="/app" />,
  },
  {
    path: "app",
    element: <AppSkeleton />,
    children: [
      {
        path: "",
        element: <Navigate to="/app/brands" />,
      },
      {
        id: "app-brands",
        path: "brands",
        element: <Brands />,
      },
      {
        id: "app-brand-details",
        path: "brands/:id",
        element: <Brand />,
      },
      {
        id: "app-reports",
        path: "reports",
        element: <Reports />,
      },
      {
        id: "app-report-data",
        path: "report-data",
        element: <ReportSource />,
      },
      {
        id: "app-taxdoo",
        path: "taxdoo/filings",
        element: <Taxdoo />,
      },
      {
        id: "filing-creation",
        path: "taxdoo/filings/:id",
        element: <TaxdooFilingDetails />,
      },
    ],
  },
  {
    path: "auth/google/callback",
    element: <GoogleAuthCallbackPage />,
  },
]);

export default router;
