import React from "react";
import { Card, Descriptions, Tag } from "antd";

export const TaxdooFilingSummary: React.FC = (props) => {
    const filingStatus = (
        <Tag color="orange" >
            In progress
        </Tag>
    )

  return (
    <Card title={"Summary"} extra={filingStatus}>
      <Descriptions>
        <Descriptions.Item label="Period">April 2023</Descriptions.Item>
        <Descriptions.Item label="Created At">3rd of May 2023</Descriptions.Item>
        <Descriptions.Item label="Created By">jan-niklas.kohlhaas@mantarobrands.com</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
